"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageDetailsToJSON = exports.StorageDetailsFromJSONTyped = exports.StorageDetailsFromJSON = void 0;
function StorageDetailsFromJSON(json) {
    return StorageDetailsFromJSONTyped(json, false);
}
exports.StorageDetailsFromJSON = StorageDetailsFromJSON;
function StorageDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'storageUsed': json['storageUsed'],
        'storageLeft': json['storageLeft'],
        'totalAvailableStorage': json['totalAvailableStorage'],
    };
}
exports.StorageDetailsFromJSONTyped = StorageDetailsFromJSONTyped;
function StorageDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'storageUsed': value.storageUsed,
        'storageLeft': value.storageLeft,
        'totalAvailableStorage': value.totalAvailableStorage,
    };
}
exports.StorageDetailsToJSON = StorageDetailsToJSON;
