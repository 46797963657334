"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestForgotPasswordResponseToJSON = exports.RequestForgotPasswordResponseFromJSONTyped = exports.RequestForgotPasswordResponseFromJSON = void 0;
function RequestForgotPasswordResponseFromJSON(json) {
    return RequestForgotPasswordResponseFromJSONTyped(json, false);
}
exports.RequestForgotPasswordResponseFromJSON = RequestForgotPasswordResponseFromJSON;
function RequestForgotPasswordResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'forgotPasswordID': json['forgotPasswordID'],
    };
}
exports.RequestForgotPasswordResponseFromJSONTyped = RequestForgotPasswordResponseFromJSONTyped;
function RequestForgotPasswordResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'forgotPasswordID': value.forgotPasswordID,
    };
}
exports.RequestForgotPasswordResponseToJSON = RequestForgotPasswordResponseToJSON;
