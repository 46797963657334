"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifyEmailBodyToJSON = exports.VerifyEmailBodyFromJSONTyped = exports.VerifyEmailBodyFromJSON = void 0;
function VerifyEmailBodyFromJSON(json) {
    return VerifyEmailBodyFromJSONTyped(json, false);
}
exports.VerifyEmailBodyFromJSON = VerifyEmailBodyFromJSON;
function VerifyEmailBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'verificationCode': json['verificationCode'],
    };
}
exports.VerifyEmailBodyFromJSONTyped = VerifyEmailBodyFromJSONTyped;
function VerifyEmailBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'verificationCode': value.verificationCode,
    };
}
exports.VerifyEmailBodyToJSON = VerifyEmailBodyToJSON;
