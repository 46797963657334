"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceInformationToJSON = exports.PriceInformationFromJSONTyped = exports.PriceInformationFromJSON = void 0;
function PriceInformationFromJSON(json) {
    return PriceInformationFromJSONTyped(json, false);
}
exports.PriceInformationFromJSON = PriceInformationFromJSON;
function PriceInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'CAD': json['CAD'],
        'DOGE': json['DOGE'],
        'BTC': json['BTC'],
    };
}
exports.PriceInformationFromJSONTyped = PriceInformationFromJSONTyped;
function PriceInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'CAD': value.CAD,
        'DOGE': value.DOGE,
        'BTC': value.BTC,
    };
}
exports.PriceInformationToJSON = PriceInformationToJSON;
