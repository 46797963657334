"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordTypeToJSON = exports.ForgotPasswordTypeFromJSONTyped = exports.ForgotPasswordTypeFromJSON = exports.ForgotPasswordType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ForgotPasswordType;
(function (ForgotPasswordType) {
    ForgotPasswordType["Admin"] = "Admin";
    ForgotPasswordType["User"] = "User";
})(ForgotPasswordType = exports.ForgotPasswordType || (exports.ForgotPasswordType = {}));
function ForgotPasswordTypeFromJSON(json) {
    return ForgotPasswordTypeFromJSONTyped(json, false);
}
exports.ForgotPasswordTypeFromJSON = ForgotPasswordTypeFromJSON;
function ForgotPasswordTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ForgotPasswordTypeFromJSONTyped = ForgotPasswordTypeFromJSONTyped;
function ForgotPasswordTypeToJSON(value) {
    return value;
}
exports.ForgotPasswordTypeToJSON = ForgotPasswordTypeToJSON;
