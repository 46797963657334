import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row} from "reactstrap";
import {Transaction} from "client";
import moment from "moment";
import {isNil} from "lodash";
import ChangeStatusModal from "./ChangeStatusModal";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;

	onClose(getNewData: boolean): void;

	transaction: Transaction;
}

interface CreateCellOptions {
	key: keyof Transaction,
	title: string,
	valueFormatter?: (v: any) => any;
	render?: (v: any) => ReactNode;
}

const ViewDetailsModal: React.FC<IProps> = ({onClose, transaction, isOpen}: IProps) => {

	const [changeStatusOpen, setChangeStatusOpen] = useState(false);

	function closeHelper(getNewData: boolean = false): void {
		setChangeStatusOpen(false);
		onClose(getNewData);
	}

	function openChangeStatusModal() {
		setChangeStatusOpen(true)
	}

	function createEntry({key, title, valueFormatter, render}: CreateCellOptions, i: number): ReactNode {
		const realValue = transaction[key];

		if (isNil(realValue)) {
			return null;
		}

		const value = valueFormatter ? valueFormatter(realValue) : realValue;
		const renderFunction = render ? render : (v) => <p>{v}</p>;

		return (
			<Col sm={12} md={6} className="mb-3" key={i}>
				<Label className="font-weight-bold">
					{title}:
				</Label>
				{renderFunction(value)}
			</Col>
		)

	}

	const entries: CreateCellOptions[] = [
		{
			key: "_id",
			title: "Internal ID (_id)",
		},
		{
			key: "creationDate",
			title: "Creation Date",
			valueFormatter: v => moment(v).format("YYYY-MM-DD hh:mm A")
		},
		{
			key: "type",
			title: "Transaction Type",
		},
		{
			key: "status",
			title: "Status",
		},
		{
			key: "user",
			title: "User's Email",
			valueFormatter: v => v?.email ? v.email : "N/A"
		},
		{
			key: "user",
			title: "User Disabled",
			valueFormatter: v => v.disabled ? "True" : "False"
		},
		{
			key: "company",
			title: "Company",
		},
		{
			key: "accountNumber",
			title: "Account Number",
		},
		{
			key: "creditCardNumber",
			title: "Credit Card Number",
		},
		{
			key: "amountCAD",
			title: "Payable Amount CAD",
			valueFormatter: v => `$${v.toFixed(2)}`,
		},
		{
			key: "feePercentage",
			title: "Fee Percentage",
			valueFormatter: v => v * 100 + "%",
		},
		{
			key: "transactionFee",
			title: "Transaction Fee",
			valueFormatter: v => `$${v.toFixed(2)}`,
		},
		{
			key: "totalCharge",
			title: "Total Charge",
			valueFormatter: v => `$${v.toFixed(2)}`,
		},
		{
			key: "btcPayPaymentRequestID",
			title: "BTC Pay ID",
		},
		{
			key: "transactionExpiry",
			title: "Pay By Transaction Expiry",
			valueFormatter: v => moment(v).format("YYYY-MM-DD hh:mm A")
		},
		{
			key: "btcPayEditURL",
			title: "Edit Invoice On BTC Pay Link",
			render: v => <p><a href={v} target="_blank">{v}</a></p>
		},
		{
			key: "btcPayViewURL",
			title: "View Invoice On BTC Pay Link",
			render: v => <p><a href={v} target="_blank">{v}</a></p>
		},
	]

	return (
		<React.Fragment>

			<ChangeStatusModal
				isOpen={changeStatusOpen}
				transaction={transaction}
				onClose={closeHelper}
			/>

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				toggle={() => closeHelper()}
				className="container mw-100"
			>
				<ModalHeader toggle={() => closeHelper()}>Transaction Details</ModalHeader>

				<ModalBody>
					<Row>
						{entries.map(createEntry)}
					</Row>
				</ModalBody>

				<ModalFooter>
					<Button color="danger" onClick={openChangeStatusModal}>
						Change Status
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	)
}

export default connect((store: IStore, props: IProps) =>
{
	return {
		...props,
		token: store.metaStore.token,
	}
}
)(ViewDetailsModal);
