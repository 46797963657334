import React from "react";
import { Container } from "reactstrap";
import OrderTable from "../components/tables/OrderTable";

const OrderManagement: React.FC = () => {

	return (
		<div className="px-3">
			<Container className="my-5">
				<div className="mb-5">
					<h1>
						Orders Management
					</h1>


				</div>

				<OrderTable/>


			</Container>

		</div>
	)
}


export default OrderManagement;