"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionFeeToJSON = exports.TransactionFeeFromJSONTyped = exports.TransactionFeeFromJSON = void 0;
const _1 = require("./");
function TransactionFeeFromJSON(json) {
    return TransactionFeeFromJSONTyped(json, false);
}
exports.TransactionFeeFromJSON = TransactionFeeFromJSON;
function TransactionFeeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'owner': _1.AdminFromJSON(json['owner']),
        'currency': _1.CurrencyFromJSON(json['currency']),
        'feePercentage': json['feePercentage'],
        'creationDate': json['creationDate'],
    };
}
exports.TransactionFeeFromJSONTyped = TransactionFeeFromJSONTyped;
function TransactionFeeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'owner': _1.AdminToJSON(value.owner),
        'currency': _1.CurrencyToJSON(value.currency),
        'feePercentage': value.feePercentage,
        'creationDate': value.creationDate,
    };
}
exports.TransactionFeeToJSON = TransactionFeeToJSON;
