"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTransactionBodyToJSON = exports.CreateTransactionBodyFromJSONTyped = exports.CreateTransactionBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateTransactionBodyFromJSON(json) {
    return CreateTransactionBodyFromJSONTyped(json, false);
}
exports.CreateTransactionBodyFromJSON = CreateTransactionBodyFromJSON;
function CreateTransactionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactionType': _1.TransactionTypeFromJSON(json['transactionType']),
        'company': json['company'],
        'amountCAD': json['amountCAD'],
        'accountNumber': !runtime_1.exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'creditCardNumber': !runtime_1.exists(json, 'creditCardNumber') ? undefined : json['creditCardNumber'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
    };
}
exports.CreateTransactionBodyFromJSONTyped = CreateTransactionBodyFromJSONTyped;
function CreateTransactionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactionType': _1.TransactionTypeToJSON(value.transactionType),
        'company': value.company,
        'amountCAD': value.amountCAD,
        'accountNumber': value.accountNumber,
        'creditCardNumber': value.creditCardNumber,
        'notes': value.notes,
    };
}
exports.CreateTransactionBodyToJSON = CreateTransactionBodyToJSON;
