import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {TableGenerator} from "frame-one-table";
import {
	SearchForTransactionsRequest,
	Transaction,
	TransactionsApi,
	TransactionStatus,
	TransactionType,
	User
} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import moment from "moment";
import {isNil, omitBy} from "lodash";
import {ContextFunctionCell, TableData} from "frame-one-table/build/contextTypes";
import {Button, ButtonGroup, Card, Input, Label, Row, Col} from "reactstrap";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import ViewDetailsModal from "../modals/transactions/ViewDetailsModal";
import ChangeStatusModal from "../modals/transactions/ChangeStatusModal";

interface IProps {
	token?: string;
	dispatch?: any;
}

const OrderTable: React.FC<IProps> = (props) => {

	const [data, setData] = useState<Array<Transaction>>([]);
	const [currentLimit, setCurrentLimit] = useState(10);
	const [total, setTotal] = useState(0);
	const [currentOffset, setCurrentOffset] = useState(0);
	const [tableLoading, setTableLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [startDate, setStartDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
	const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.BILL);
	const [transactionStatus, setTransactionStatus] = useState();
	const [availableOffsets, setAvailableOffsets] = useState<number[]>();
	const [enablePrevious, setEnablePrevious] = useState(false);

	/**
	 * Query on parameter changes
	 */
	useEffect(() => {
		requestTableData().then().catch()
	}, [
		currentOffset,
		currentLimit
	])

	/**
	 * Request new data
	 */
	async function requestTableData() {
		setTableLoading(true);
		try {
			const res = await new TransactionsApi(getConfig(props.token)).searchForTransactions(omitBy({
				transactionType,
				limit: currentLimit,
				offset: currentOffset,
				search: search.length < 1 ? "" : search,
				startDate: startDate ? moment(startDate, "YYYY-MM-DD").startOf("day").valueOf() : undefined,
				endDate: endDate ? moment(endDate, "YYYY-MM-DD").endOf("day").valueOf() : undefined,
				transactionStatus,
				sort: true,
				numberOfAvailableOffsets: 3,
			}, isNil) as SearchForTransactionsRequest);
			setEnablePrevious(res.paginationInfo.enablePrevious);
			setAvailableOffsets(res.paginationInfo.availableOffsets.filter(v => !isNil(v)));
			setTotal(res.paginationInfo.total);
			setData(res.transactions);

		} catch (err) {
			console.error(err);
			props.dispatch(addError(err));
		}
		setTableLoading(false);
	}

	function createInputOnChange(onChange: (v) => void ): ChangeEventHandler<HTMLInputElement> {
		return e => {
			onChange(e.target.value);
		}
	}

	async function onLimitChange(v) {
		console.log("limit", v)
		setCurrentLimit(v);
	}

	async function onOffsetChange(v) {
		console.log("offset", v)
		setCurrentOffset(v);
	}

	async function onCloseHelper(getNewData: boolean) {
		if (getNewData) {
			await requestTableData();
		}
	}

	const createActionButtons: ContextFunctionCell<ReactNode> = (v: User, t: Transaction) => {
		return <ActionButtons transaction={t} onClose={onCloseHelper}/>
	}

	return (
		<React.Fragment>
			<div className="w-100 mb-3">
				<Row>
					<Col md={12} lg={6} className="mb-3">
						<Label className="font-weight-bold">
							Search Query
						</Label>
						<Input
							disabled={tableLoading}
							value={search}
							onChange={createInputOnChange(setSearch)}
						/>
					</Col>
					<Col md={12} lg={6} className="mb-3">
						<Label className="font-weight-bold">
							Transaction Type
						</Label>
						<Input
							type="select"
							disabled={tableLoading}
							value={transactionType}
							onChange={createInputOnChange(setTransactionType)}
						>
							<option>{TransactionType.BILL}</option>
							<option>{TransactionType.CREDITCARD}</option>
						</Input>
					</Col>
					<Col md={12} lg={6} className="mb-3">
						<Label className="font-weight-bold">
							Start Date
						</Label>
						<Input
							type="date"
							disabled={tableLoading}
							value={startDate}
							onChange={createInputOnChange(setStartDate)}
						/>
					</Col>
					<Col md={12} lg={6} className="mb-3">
						<Label className="font-weight-bold">
							End Date
						</Label>
						<Input
							type="date"
							disabled={tableLoading}
							value={endDate}
							onChange={createInputOnChange(setEndDate)}
						/>
					</Col>
					<Col md={12} lg={6} className="mb-3">
						<Label className="font-weight-bold">
							Transaction Status
						</Label>
						<Input
							type="select"
							disabled={tableLoading}
							value={transactionStatus}
							onChange={createInputOnChange(setTransactionStatus)}
						>
							<option selected value={undefined}>ANY</option>
							<option>{TransactionStatus.COMPLETED}</option>
							<option>{TransactionStatus.PAID}</option>
							<option>{TransactionStatus.PENDING}</option>
							<option>{TransactionStatus.PENDINGCANCELLED}</option>
							<option>{TransactionStatus.USERCANCELLED}</option>
							<option>{TransactionStatus.REFUNDED}</option>
						</Input>
					</Col>
				</Row>

				<Button color="primary" onClick={requestTableData}>Search</Button>
			</div>
			<TableGenerator
				showHeader={true}
				showBody={true}
				showSortIcons={true}
				loading={tableLoading}
				columnOptions={[
					{
						key: "creationDate",
						showSortIcons: true,
						headerValue: "Submission Date",
						sortable: true,
						valueFormatter: (v: number) => moment(v).format("YYYY-MM-DD hh:mm A")
					},
					{
						key: "status",
						showSortIcons: true,
						headerValue: "Status",
						sortable: true,
					},
					{
						key: "user",
						showSortIcons: true,
						headerValue: "User",
						sortable: true,
						valueFormatter: (v: User) => v?.email ? v.email : "N/A",
					},
					{
						key: "amountCAD",
						showSortIcons: true,
						headerValue: "CAD Payable",
						sortable: true,
						valueFormatter: (v: number) => `$${v.toFixed(2)}`,
					},
					{
						key: "btcPayPaymentRequestID",
						showSortIcons: false,
						headerValue: "BTC Pay ID",
						cellRender: (v: string, row: Transaction) => <><a target="_blank"
																		  href={row.btcPayViewURL}>{v} </a><a
							href={row.btcPayEditURL} target="_blank"><b>(EDIT)</b></a></>
					},
					{
						key: "user",
						showSortIcons: false,
						headerValue: "Actions",
						cellRender: createActionButtons,
					},
					{
						key: "notes",
						showSortIcons: false,
						headerValue: "Notes",
					},
				]}
				paginatorProps={{
					enableGoPrevious: enablePrevious,
					show: data.length > 0,
					currentLimit,
					total,
					limitOptions: [10, 25, 50, 100, 500],
					currentOffset,
					onLimitChange,
					availableOffsets,
					onOffsetChange,
				}}
				data={data}
			/>
		</React.Fragment>

	)
}

interface IActionButtonsProps {
	transaction: Transaction,

	onClose(getNewData: boolean): void;
}

const ActionButtons: React.FC<IActionButtonsProps> = (props) => {

	const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
	const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);

	function onCloseHelper(getNewData: boolean) {
		setIsViewDetailsModalOpen(false);
		setIsChangeStatusOpen(false);
		props.onClose(getNewData);
	}

	function openDetails() {
		setIsViewDetailsModalOpen(true);
	}

	function openChangeStatus() {
		setIsChangeStatusOpen(true);
	}

	return (
		<React.Fragment>
			<ViewDetailsModal
				transaction={props.transaction}
				onClose={onCloseHelper}
				isOpen={isViewDetailsModalOpen}
			/>
			<ChangeStatusModal
				transaction={props.transaction}
				onClose={onCloseHelper}
				isOpen={isChangeStatusOpen}
			/>
			<ButtonGroup>
				<Button color="primary" onClick={openDetails}>
					See Details
				</Button>
				<Button color="danger" onClick={openChangeStatus}>
					Change Status
				</Button>
			</ButtonGroup>
		</React.Fragment>
	)
}

export default connect((store: IStore, props: IProps) => {
		return {
			...props,
			token: store.metaStore.token,
		}
	}
)(OrderTable);
