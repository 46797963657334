"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailVerificationToJSON = exports.EmailVerificationFromJSONTyped = exports.EmailVerificationFromJSON = void 0;
const _1 = require("./");
function EmailVerificationFromJSON(json) {
    return EmailVerificationFromJSONTyped(json, false);
}
exports.EmailVerificationFromJSON = EmailVerificationFromJSON;
function EmailVerificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'emailVerificationType': _1.EmailVerificationTypeFromJSON(json['emailVerificationType']),
        'user': json['user'],
        'email': json['email'],
        'verificationCodeHash': json['verificationCodeHash'],
        'creationDate': json['creationDate'],
    };
}
exports.EmailVerificationFromJSONTyped = EmailVerificationFromJSONTyped;
function EmailVerificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'emailVerificationType': _1.EmailVerificationTypeToJSON(value.emailVerificationType),
        'user': value.user,
        'email': value.email,
        'verificationCodeHash': value.verificationCodeHash,
        'creationDate': value.creationDate,
    };
}
exports.EmailVerificationToJSON = EmailVerificationToJSON;
