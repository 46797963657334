"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeTransactionStatusBodyToJSON = exports.ChangeTransactionStatusBodyFromJSONTyped = exports.ChangeTransactionStatusBodyFromJSON = void 0;
const _1 = require("./");
function ChangeTransactionStatusBodyFromJSON(json) {
    return ChangeTransactionStatusBodyFromJSONTyped(json, false);
}
exports.ChangeTransactionStatusBodyFromJSON = ChangeTransactionStatusBodyFromJSON;
function ChangeTransactionStatusBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transactionID': json['transactionID'],
        'transactionStatus': _1.TransactionStatusFromJSON(json['transactionStatus']),
    };
}
exports.ChangeTransactionStatusBodyFromJSONTyped = ChangeTransactionStatusBodyFromJSONTyped;
function ChangeTransactionStatusBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transactionID': value.transactionID,
        'transactionStatus': _1.TransactionStatusToJSON(value.transactionStatus),
    };
}
exports.ChangeTransactionStatusBodyToJSON = ChangeTransactionStatusBodyToJSON;
