import {Transaction, TransactionsApi, TransactionStatus} from "client";
import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;

	onClose(getNewData: boolean): void;

	transaction: Transaction;
}

const ChangeStatusModal: React.FC<IProps> = ({onClose, transaction, isOpen, dispatch, token}: IProps) => {

	const [status, setStatus] = useState<TransactionStatus>(transaction.status);

	function closeHelper(getNewData: boolean = false): void {
		onClose(getNewData);
	}

	async function callSetNewStatusAPI() {
		dispatch(incrementLoading());
		try {
			await new TransactionsApi(getConfig(token)).changeTransactionStatus({
				changeTransactionStatusBody: {
					transactionID: transaction._id,
					transactionStatus: status,
				}
			})
			closeHelper(true)
		} catch (err) {
			dispatch(addError(err))
		}
		dispatch(decrementLoading());
	}

	const onSelectStatusChange: ChangeEventHandler<HTMLInputElement> = e => {
		setStatus(e.target.value as TransactionStatus);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={() => closeHelper()}
		>
			<ModalHeader toggle={() => closeHelper()}>Change Transaction Status</ModalHeader>

			<ModalBody>

				<Label className="font-weight-bold">
					Current Status:
				</Label>
				<p>{transaction.status}</p>

				<hr/>

				<Label className="font-weight-bold">
					Select New Status:
				</Label>
				<Input
					type="select"
					value={status}
					onChange={onSelectStatusChange}
				>
					<option>{TransactionStatus.COMPLETED}</option>
					<option>{TransactionStatus.PAID}</option>
					<option>{TransactionStatus.PENDING}</option>
					<option>{TransactionStatus.PENDINGCANCELLED}</option>
					<option>{TransactionStatus.USERCANCELLED}</option>
					<option>{TransactionStatus.REFUNDED}</option>
				</Input>
			</ModalBody>

			<ModalFooter>
				<Button color="danger" onClick={callSetNewStatusAPI}>
					Confirm Change Status
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(ChangeStatusModal);

