"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeAdminPasswordBodyToJSON = exports.ChangeAdminPasswordBodyFromJSONTyped = exports.ChangeAdminPasswordBodyFromJSON = void 0;
function ChangeAdminPasswordBodyFromJSON(json) {
    return ChangeAdminPasswordBodyFromJSONTyped(json, false);
}
exports.ChangeAdminPasswordBodyFromJSON = ChangeAdminPasswordBodyFromJSON;
function ChangeAdminPasswordBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'adminID': json['adminID'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
    };
}
exports.ChangeAdminPasswordBodyFromJSONTyped = ChangeAdminPasswordBodyFromJSONTyped;
function ChangeAdminPasswordBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'adminID': value.adminID,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
    };
}
exports.ChangeAdminPasswordBodyToJSON = ChangeAdminPasswordBodyToJSON;
