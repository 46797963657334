"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class TransactionsApi extends runtime.BaseAPI {
    /**
     * Used by users to cancel a pending transaction.
     */
    async cancelTransactionRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/cancel_transaction`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to cancel a pending transaction.
     */
    async cancelTransaction(requestParameters) {
        await this.cancelTransactionRaw(requestParameters);
    }
    /**
     * Used by GOD admins to update the status of a transaction.
     */
    async changeTransactionStatusRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/change_transaction_status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeTransactionStatusBodyToJSON(requestParameters.changeTransactionStatusBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to update the status of a transaction.
     */
    async changeTransactionStatus(requestParameters) {
        await this.changeTransactionStatusRaw(requestParameters);
    }
    /**
     * API users call to submit a bill or credit card payment request
     */
    async createTransactionRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/create_transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateTransactionBodyToJSON(requestParameters.createTransactionBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CreateTransactionResponseFromJSON(jsonValue));
    }
    /**
     * API users call to submit a bill or credit card payment request
     */
    async createTransaction(requestParameters) {
        const response = await this.createTransactionRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPriceInformationRaw(requestParameters) {
        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency', 'Required parameter requestParameters.currency was null or undefined when calling getPriceInformation.');
        }
        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount', 'Required parameter requestParameters.amount was null or undefined when calling getPriceInformation.');
        }
        const queryParameters = {};
        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }
        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/transactions/get_price_information`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PriceInformationFromJSON(jsonValue));
    }
    /**
     */
    async getPriceInformation(requestParameters) {
        const response = await this.getPriceInformationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async searchForTransactionsRaw(requestParameters) {
        if (requestParameters.transactionType === null || requestParameters.transactionType === undefined) {
            throw new runtime.RequiredError('transactionType', 'Required parameter requestParameters.transactionType was null or undefined when calling searchForTransactions.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling searchForTransactions.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling searchForTransactions.');
        }
        const queryParameters = {};
        if (requestParameters.transactionType !== undefined) {
            queryParameters['transactionType'] = requestParameters.transactionType;
        }
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.transactionStatus !== undefined) {
            queryParameters['transactionStatus'] = requestParameters.transactionStatus;
        }
        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }
        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/search_for_transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SearchForTransactionsResponseFromJSON(jsonValue));
    }
    /**
     */
    async searchForTransactions(requestParameters) {
        const response = await this.searchForTransactionsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async userSearchForTransactionsRaw(requestParameters) {
        if (requestParameters.transactionType === null || requestParameters.transactionType === undefined) {
            throw new runtime.RequiredError('transactionType', 'Required parameter requestParameters.transactionType was null or undefined when calling userSearchForTransactions.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling userSearchForTransactions.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling userSearchForTransactions.');
        }
        const queryParameters = {};
        if (requestParameters.transactionType !== undefined) {
            queryParameters['transactionType'] = requestParameters.transactionType;
        }
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.transactionStatus !== undefined) {
            queryParameters['transactionStatus'] = requestParameters.transactionStatus;
        }
        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }
        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }
        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/transactions/user_search_for_transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SearchForTransactionsResponseFromJSON(jsonValue));
    }
    /**
     */
    async userSearchForTransactions(requestParameters) {
        const response = await this.userSearchForTransactionsRaw(requestParameters);
        return await response.value();
    }
}
exports.TransactionsApi = TransactionsApi;
