"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AdminApi extends runtime.BaseAPI {
    /**
     */
    async adminGetProfileRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/admin_get_profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdminFromJSON(jsonValue));
    }
    /**
     */
    async adminGetProfile() {
        const response = await this.adminGetProfileRaw();
        return await response.value();
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLoginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admin/admin_login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminLoginBodyToJSON(requestParameters.adminLoginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLogin(requestParameters) {
        const response = await this.adminLoginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by admins to request a forgot password verification code
     */
    async adminRequestForgotPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admin/admin_request_forgot_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminRequestForgotPasswordBodyToJSON(requestParameters.adminRequestForgotPasswordBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RequestForgotPasswordResponseFromJSON(jsonValue));
    }
    /**
     * Used by admins to request a forgot password verification code
     */
    async adminRequestForgotPassword(requestParameters) {
        const response = await this.adminRequestForgotPasswordRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by admins to change their password if they forgot it.
     */
    async adminResetForgotPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admin/admin_reset_forgot_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ResetForgotPasswordBodyToJSON(requestParameters.resetForgotPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to change their password if they forgot it.
     */
    async adminResetForgotPassword(requestParameters) {
        await this.adminResetForgotPasswordRaw(requestParameters);
    }
    /**
     * Used by admins to update their emails.
     */
    async adminUpdateEmailRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/admin_update_email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateEmailBodyToJSON(requestParameters.updateEmailBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to update their emails.
     */
    async adminUpdateEmail(requestParameters) {
        await this.adminUpdateEmailRaw(requestParameters);
    }
    /**
     * Used by admins to update their profile.
     */
    async adminUpdateProfileRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/admin_update_profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminUpdateProfileBodyToJSON(requestParameters.adminUpdateProfileBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to update their profile.
     */
    async adminUpdateProfile(requestParameters) {
        await this.adminUpdateProfileRaw(requestParameters);
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_admin_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeAdminPasswordBodyToJSON(requestParameters.changeAdminPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPassword(requestParameters) {
        await this.changeAdminPasswordRaw(requestParameters);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_user_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters) {
        await this.changeUserPasswordRaw(requestParameters);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async createAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/create_admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateAdminBodyToJSON(requestParameters.createAdminBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async createAdmin(requestParameters) {
        await this.createAdminRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable an admin
     */
    async disableAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_admin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable an admin
     */
    async disableAdmin(requestParameters) {
        await this.disableAdminRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable a user
     */
    async disableUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable a user
     */
    async disableUser(requestParameters) {
        await this.disableUserRaw(requestParameters);
    }
    /**
     * Used by GOD admins to enable an admin
     */
    async enableAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/enable_admin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to enable an admin
     */
    async enableAdmin(requestParameters) {
        await this.enableAdminRaw(requestParameters);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/enable_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUser(requestParameters) {
        await this.enableUserRaw(requestParameters);
    }
    /**
     */
    async getAdminListRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getAdminList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getAdminList.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_admin_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetAdminListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getAdminList(requestParameters) {
        const response = await this.getAdminListRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getAdminProfileRaw(requestParameters) {
        if (requestParameters.adminID === null || requestParameters.adminID === undefined) {
            throw new runtime.RequiredError('adminID', 'Required parameter requestParameters.adminID was null or undefined when calling getAdminProfile.');
        }
        const queryParameters = {};
        if (requestParameters.adminID !== undefined) {
            queryParameters['adminID'] = requestParameters.adminID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_admin_profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdminFromJSON(jsonValue));
    }
    /**
     */
    async getAdminProfile(requestParameters) {
        const response = await this.getAdminProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTransactionFeeChangeHistoryRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getTransactionFeeChangeHistory.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getTransactionFeeChangeHistory.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_transaction_fee_change_history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.TransactionFeeFromJSON));
    }
    /**
     */
    async getTransactionFeeChangeHistory(requestParameters) {
        const response = await this.getTransactionFeeChangeHistoryRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUserListRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getUserList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getUserList.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_user_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserListResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUserList(requestParameters) {
        const response = await this.getUserListRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUserProfileRaw(requestParameters) {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID', 'Required parameter requestParameters.userID was null or undefined when calling getUserProfile.');
        }
        const queryParameters = {};
        if (requestParameters.userID !== undefined) {
            queryParameters['userID'] = requestParameters.userID;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_user_profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UserFromJSON(jsonValue));
    }
    /**
     */
    async getUserProfile(requestParameters) {
        const response = await this.getUserProfileRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by admins to update the transaction fee
     */
    async updateTransactionFeeRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/update_transaction_fee`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateTransactionFeeBodyToJSON(requestParameters.updateTransactionFeeBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to update the transaction fee
     */
    async updateTransactionFee(requestParameters) {
        await this.updateTransactionFeeRaw(requestParameters);
    }
}
exports.AdminApi = AdminApi;
