import React, {ReactNode, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Button} from "reactstrap";
import UpdateUserPasswordModal from "../modals/user/UpdateUserPasswordModal";
import EnableUserModal from "../modals/user/EnableUserModal";
import DisableUserModal from "../modals/user/DisableUserModal";
import {User} from "client";

interface IProps {
    data: Array<User>;

    onDoneAction(): void;
}

const UsersTable: React.FC<IProps> = (props: IProps) => {

    const {data} = props;

    function makeUserButtons(cell: string, row: User): ReactNode {
        return (<UserButtons user={row} onDone={props.onDoneAction}/>);
    }

    return (
        <BootstrapTable
            bootstrap4={true}
            striped={false}
            hover={true}
            condensed={true}
            bordered={true}
            wrapperClasses="table-responsive"
            rowStyle={{whiteSpace: "nowrap"}}
            noDataIndication={data === undefined ? "Loading..." : "No Users"}
            defaultSorted={[{dataField: "email", order: "asc"}]}
            data={data ? data : []}
            keyField="_id"
            columns={[
                {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                },
                {
                    dataField: "active",
                    text: "Enabled",
                    sort: true,
                    classes: (c) => c ? "text-success" : "text-danger",
                },
                {
                    dataField: "actions",
                    text: "Manage Tools",
                    formatter: makeUserButtons,
                },
            ]}
        />
    );
};

interface IUserButtonsProps {
    user: User;

    onDone(): void;
}

const UserButtons: React.FC<IUserButtonsProps> = (props: IUserButtonsProps) => {

    const [passwordOpen, setPasswordOpen] = useState(false);
    const [enabledOpen, setEnabledOpen] = useState(false);
    const [disabledOpen, setDisabledOpen] = useState(false);

    function togglePasswordOpen(): void {
        setPasswordOpen(!passwordOpen);
    }

    function toggleEnabledOpen(): void {
        setEnabledOpen(!enabledOpen);
    }

    function toggleDisabledOpen(): void {
        setDisabledOpen(!disabledOpen);
    }

    function done(): void {
        setPasswordOpen(false);
        setEnabledOpen(false);
        setDisabledOpen(false);
        props.onDone();
    }

    return (
        <React.Fragment>
            <UpdateUserPasswordModal isOpen={passwordOpen} user={props.user} onClose={togglePasswordOpen}
                                     onDone={done}/>
            <EnableUserModal isOpen={enabledOpen} user={props.user} onClose={toggleEnabledOpen} onDone={done}/>
            <DisableUserModal isOpen={disabledOpen} user={props.user} onClose={toggleDisabledOpen} onDone={done}/>

            <div className="d-flex">
                <Button color="primary" onClick={togglePasswordOpen} className="mr-3">
                    Update Password
                </Button>

                {props.user.disabled ? (
                    <Button color="success" onClick={toggleEnabledOpen} className="mr-3">
                        Enable User
                    </Button>
                ) : (
                    <Button color="danger" onClick={toggleDisabledOpen} className="mr-3">
                        Disable User
                    </Button>
                )}

            </div>
        </React.Fragment>
    );
};

export default UsersTable;
