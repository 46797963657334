"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIErrorToJSON = exports.APIErrorFromJSONTyped = exports.APIErrorFromJSON = void 0;
const runtime_1 = require("../runtime");
function APIErrorFromJSON(json) {
    return APIErrorFromJSONTyped(json, false);
}
exports.APIErrorFromJSON = APIErrorFromJSON;
function APIErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'messages': json['messages'],
        'date': !runtime_1.exists(json, 'date') ? undefined : json['date'],
        'method': !runtime_1.exists(json, 'method') ? undefined : json['method'],
        'path': !runtime_1.exists(json, 'path') ? undefined : json['path'],
        'errorMessage': !runtime_1.exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}
exports.APIErrorFromJSONTyped = APIErrorFromJSONTyped;
function APIErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'messages': value.messages,
        'date': value.date,
        'method': value.method,
        'path': value.path,
        'errorMessage': value.errorMessage,
    };
}
exports.APIErrorToJSON = APIErrorToJSON;
