"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPriceInformationParametersToJSON = exports.GetPriceInformationParametersFromJSONTyped = exports.GetPriceInformationParametersFromJSON = void 0;
const _1 = require("./");
function GetPriceInformationParametersFromJSON(json) {
    return GetPriceInformationParametersFromJSONTyped(json, false);
}
exports.GetPriceInformationParametersFromJSON = GetPriceInformationParametersFromJSON;
function GetPriceInformationParametersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': _1.CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
    };
}
exports.GetPriceInformationParametersFromJSONTyped = GetPriceInformationParametersFromJSONTyped;
function GetPriceInformationParametersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': _1.CurrencyToJSON(value.currency),
        'amount': value.amount,
    };
}
exports.GetPriceInformationParametersToJSON = GetPriceInformationParametersToJSON;
