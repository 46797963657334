"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailVerificationTypeToJSON = exports.EmailVerificationTypeFromJSONTyped = exports.EmailVerificationTypeFromJSON = exports.EmailVerificationType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var EmailVerificationType;
(function (EmailVerificationType) {
    EmailVerificationType["Admin"] = "Admin";
    EmailVerificationType["User"] = "User";
})(EmailVerificationType = exports.EmailVerificationType || (exports.EmailVerificationType = {}));
function EmailVerificationTypeFromJSON(json) {
    return EmailVerificationTypeFromJSONTyped(json, false);
}
exports.EmailVerificationTypeFromJSON = EmailVerificationTypeFromJSON;
function EmailVerificationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EmailVerificationTypeFromJSONTyped = EmailVerificationTypeFromJSONTyped;
function EmailVerificationTypeToJSON(value) {
    return value;
}
exports.EmailVerificationTypeToJSON = EmailVerificationTypeToJSON;
