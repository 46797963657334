"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = void 0;
const runtime_1 = require("../runtime");
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'disabled': json['disabled'],
        'email': json['email'],
        'normalizedEmail': !runtime_1.exists(json, 'normalizedEmail') ? undefined : json['normalizedEmail'],
        'verifiedEmail': json['verifiedEmail'],
        'passwordHash': !runtime_1.exists(json, 'passwordHash') ? undefined : json['passwordHash'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'disabled': value.disabled,
        'email': value.email,
        'normalizedEmail': value.normalizedEmail,
        'verifiedEmail': value.verifiedEmail,
        'passwordHash': value.passwordHash,
    };
}
exports.UserToJSON = UserToJSON;
