"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTransactionResponseToJSON = exports.CreateTransactionResponseFromJSONTyped = exports.CreateTransactionResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateTransactionResponseFromJSON(json) {
    return CreateTransactionResponseFromJSONTyped(json, false);
}
exports.CreateTransactionResponseFromJSON = CreateTransactionResponseFromJSON;
function CreateTransactionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
        'btcPayPaymentRequestID': !runtime_1.exists(json, 'btcPayPaymentRequestID') ? undefined : json['btcPayPaymentRequestID'],
        'transactionExpiry': !runtime_1.exists(json, 'transactionExpiry') ? undefined : json['transactionExpiry'],
    };
}
exports.CreateTransactionResponseFromJSONTyped = CreateTransactionResponseFromJSONTyped;
function CreateTransactionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'btcPayPaymentRequestID': value.btcPayPaymentRequestID,
        'transactionExpiry': value.transactionExpiry,
    };
}
exports.CreateTransactionResponseToJSON = CreateTransactionResponseToJSON;
