"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateEmailBodyToJSON = exports.UpdateEmailBodyFromJSONTyped = exports.UpdateEmailBodyFromJSON = void 0;
function UpdateEmailBodyFromJSON(json) {
    return UpdateEmailBodyFromJSONTyped(json, false);
}
exports.UpdateEmailBodyFromJSON = UpdateEmailBodyFromJSON;
function UpdateEmailBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
    };
}
exports.UpdateEmailBodyFromJSONTyped = UpdateEmailBodyFromJSONTyped;
function UpdateEmailBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
    };
}
exports.UpdateEmailBodyToJSON = UpdateEmailBodyToJSON;
