"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTransactionFeeChangeHistoryResponseToJSON = exports.GetTransactionFeeChangeHistoryResponseFromJSONTyped = exports.GetTransactionFeeChangeHistoryResponseFromJSON = void 0;
const _1 = require("./");
function GetTransactionFeeChangeHistoryResponseFromJSON(json) {
    return GetTransactionFeeChangeHistoryResponseFromJSONTyped(json, false);
}
exports.GetTransactionFeeChangeHistoryResponseFromJSON = GetTransactionFeeChangeHistoryResponseFromJSON;
function GetTransactionFeeChangeHistoryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': _1.PaginationInfoFromJSON(json['paginationInfo']),
        'transactionFees': (json['transactionFees'].map(_1.TransactionFeeFromJSON)),
    };
}
exports.GetTransactionFeeChangeHistoryResponseFromJSONTyped = GetTransactionFeeChangeHistoryResponseFromJSONTyped;
function GetTransactionFeeChangeHistoryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': _1.PaginationInfoToJSON(value.paginationInfo),
        'transactionFees': (value.transactionFees.map(_1.TransactionFeeToJSON)),
    };
}
exports.GetTransactionFeeChangeHistoryResponseToJSON = GetTransactionFeeChangeHistoryResponseToJSON;
