"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyToJSON = exports.CurrencyFromJSONTyped = exports.CurrencyFromJSON = exports.Currency = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var Currency;
(function (Currency) {
    Currency["CAD"] = "CAD";
    Currency["BTC"] = "BTC";
    Currency["DOGE"] = "DOGE";
})(Currency = exports.Currency || (exports.Currency = {}));
function CurrencyFromJSON(json) {
    return CurrencyFromJSONTyped(json, false);
}
exports.CurrencyFromJSON = CurrencyFromJSON;
function CurrencyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CurrencyFromJSONTyped = CurrencyFromJSONTyped;
function CurrencyToJSON(value) {
    return value;
}
exports.CurrencyToJSON = CurrencyToJSON;
