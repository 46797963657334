"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionToJSON = exports.TransactionFromJSONTyped = exports.TransactionFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TransactionFromJSON(json) {
    return TransactionFromJSONTyped(json, false);
}
exports.TransactionFromJSON = TransactionFromJSON;
function TransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'creationDate': json['creationDate'],
        'type': _1.TransactionTypeFromJSON(json['type']),
        'status': _1.TransactionStatusFromJSON(json['status']),
        'user': _1.UserFromJSON(json['user']),
        'company': json['company'],
        'accountNumber': !runtime_1.exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'creditCardNumber': !runtime_1.exists(json, 'creditCardNumber') ? undefined : json['creditCardNumber'],
        'amountCAD': json['amountCAD'],
        'feePercentage': json['feePercentage'],
        'transactionFee': json['transactionFee'],
        'totalCharge': json['totalCharge'],
        'btcPayPaymentRequestID': !runtime_1.exists(json, 'btcPayPaymentRequestID') ? undefined : json['btcPayPaymentRequestID'],
        'btcPayEditURL': !runtime_1.exists(json, 'btcPayEditURL') ? undefined : json['btcPayEditURL'],
        'btcPayViewURL': !runtime_1.exists(json, 'btcPayViewURL') ? undefined : json['btcPayViewURL'],
        'transactionExpiry': json['transactionExpiry'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
    };
}
exports.TransactionFromJSONTyped = TransactionFromJSONTyped;
function TransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'creationDate': value.creationDate,
        'type': _1.TransactionTypeToJSON(value.type),
        'status': _1.TransactionStatusToJSON(value.status),
        'user': _1.UserToJSON(value.user),
        'company': value.company,
        'accountNumber': value.accountNumber,
        'creditCardNumber': value.creditCardNumber,
        'amountCAD': value.amountCAD,
        'feePercentage': value.feePercentage,
        'transactionFee': value.transactionFee,
        'totalCharge': value.totalCharge,
        'btcPayPaymentRequestID': value.btcPayPaymentRequestID,
        'btcPayEditURL': value.btcPayEditURL,
        'btcPayViewURL': value.btcPayViewURL,
        'transactionExpiry': value.transactionExpiry,
        'notes': value.notes,
    };
}
exports.TransactionToJSON = TransactionToJSON;
