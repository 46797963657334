"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationInfoToJSON = exports.PaginationInfoFromJSONTyped = exports.PaginationInfoFromJSON = void 0;
function PaginationInfoFromJSON(json) {
    return PaginationInfoFromJSONTyped(json, false);
}
exports.PaginationInfoFromJSON = PaginationInfoFromJSON;
function PaginationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enablePrevious': json['enablePrevious'],
        'enableNext': json['enableNext'],
        'lowerRange': json['lowerRange'],
        'upperRange': json['upperRange'],
        'total': json['total'],
        'availableOffsets': json['availableOffsets'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}
exports.PaginationInfoFromJSONTyped = PaginationInfoFromJSONTyped;
function PaginationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enablePrevious': value.enablePrevious,
        'enableNext': value.enableNext,
        'lowerRange': value.lowerRange,
        'upperRange': value.upperRange,
        'total': value.total,
        'availableOffsets': value.availableOffsets,
        'limit': value.limit,
        'offset': value.offset,
    };
}
exports.PaginationInfoToJSON = PaginationInfoToJSON;
