"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatusToJSON = exports.TransactionStatusFromJSONTyped = exports.TransactionStatusFromJSON = exports.TransactionStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["PENDING"] = "PENDING";
    TransactionStatus["PENDINGCANCELLED"] = "PENDING_CANCELLED";
    TransactionStatus["USERCANCELLED"] = "USER_CANCELLED";
    TransactionStatus["PAID"] = "PAID";
    TransactionStatus["COMPLETED"] = "COMPLETED";
    TransactionStatus["REFUNDED"] = "REFUNDED";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
function TransactionStatusFromJSON(json) {
    return TransactionStatusFromJSONTyped(json, false);
}
exports.TransactionStatusFromJSON = TransactionStatusFromJSON;
function TransactionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionStatusFromJSONTyped = TransactionStatusFromJSONTyped;
function TransactionStatusToJSON(value) {
    return value;
}
exports.TransactionStatusToJSON = TransactionStatusToJSON;
