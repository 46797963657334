"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminUpdateProfileBodyToJSON = exports.AdminUpdateProfileBodyFromJSONTyped = exports.AdminUpdateProfileBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminUpdateProfileBodyFromJSON(json) {
    return AdminUpdateProfileBodyFromJSONTyped(json, false);
}
exports.AdminUpdateProfileBodyFromJSON = AdminUpdateProfileBodyFromJSON;
function AdminUpdateProfileBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': !runtime_1.exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !runtime_1.exists(json, 'lastName') ? undefined : json['lastName'],
    };
}
exports.AdminUpdateProfileBodyFromJSONTyped = AdminUpdateProfileBodyFromJSONTyped;
function AdminUpdateProfileBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.AdminUpdateProfileBodyToJSON = AdminUpdateProfileBodyToJSON;
