"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * billcy
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.8-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordToJSON = exports.ForgotPasswordFromJSONTyped = exports.ForgotPasswordFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ForgotPasswordFromJSON(json) {
    return ForgotPasswordFromJSONTyped(json, false);
}
exports.ForgotPasswordFromJSON = ForgotPasswordFromJSON;
function ForgotPasswordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'forgotPasswordType': !runtime_1.exists(json, 'forgotPasswordType') ? undefined : _1.ForgotPasswordTypeFromJSON(json['forgotPasswordType']),
        'user': json['user'],
        'verificationCodeHash': json['verificationCodeHash'],
        'creationDate': json['creationDate'],
    };
}
exports.ForgotPasswordFromJSONTyped = ForgotPasswordFromJSONTyped;
function ForgotPasswordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'forgotPasswordType': _1.ForgotPasswordTypeToJSON(value.forgotPasswordType),
        'user': value.user,
        'verificationCodeHash': value.verificationCodeHash,
        'creationDate': value.creationDate,
    };
}
exports.ForgotPasswordToJSON = ForgotPasswordToJSON;
